import * as React from "react";
import { useEffect } from "react";
import "./layout/styles.scss";
import {
  SEOMetaTags,
  CallToAction,
  GreyFooter,
  BlogBreadcrumbs,
  FundingBanner,
} from "../components";
import MainMenu from "../components/MainMenu/MainMenu";
import BlogPost from "../components/BlogPost/BlogPost";
import BackToTopSign from "../components/BackToTopSign/BackToTopSign";
import ShareButtons from "../components/ShareButtons/ShareButtons";
import { Disqus } from "gatsby-plugin-disqus";
import { IBlogPostTemplate } from "./interfaces";
import FormNewsletter from '../components/FormNewsletter/FormNewsletter';

const BlogPostTemplate = (data: IBlogPostTemplate): JSX.Element => {

  useEffect(() => {
    const bodyStyle = document.body.style;
    bodyStyle.opacity = "1";
    bodyStyle.transition = "opacity 0.3s";
  }, []);

  let { menu, lang, footer, urlName, cta, c2c, banner, category, formNewsletter } = data.pageContext;
  const url = data.pageContext && data.pageContext.data.url;
  const articleTitle = data.pageContext && data.pageContext.data.title;
  const excludeReadtime = data.pageContext && data.pageContext.data.excludeReadtime;

  let disqusConfig: Disqus = {
    url: `https://pushpushgo.com${ data.pageContext.data.url }`,
    identifier: data.pageContext.data.url,
    title: data.pageContext.data.title || "",
  };

  const alternateLocale = lang && (lang === "pl" ? "en" : "pl");
  const alternateSlugData = data && data.pageContext.data._allSlugLocales.find(el => el.locale !== lang);
  const alternateSlug = alternateSlugData && alternateSlugData.value;
  const alternateLink = alternateSlug && `https://pushpushgo.com/${ alternateLocale }/${ urlName }/${ alternateSlug }/`;
  const alternateLinks = [{ locale: alternateLocale, link: alternateLink }];

  return (
    <>
      <MainMenu menu={ menu } lang={ lang } c2c={ c2c }/>
      <SEOMetaTags { ...data.pageContext.data.seoMetaTags }
                   pageName={ articleTitle }
                   lang={ lang }
                   alternateLinks={ alternateLinks }/>
      <section className="blog">
        <div className="content-limiter">
          <BlogBreadcrumbs pages={ [{
            key: "page", value: `${ category }`,
            url: urlName === "blog" ? `/${ lang }/${ urlName }/page/1` : `/${ lang }/${ urlName }/1`,
          },
            { key: "title", value: data.pageContext.data.title }] }/>
          <BlogPost { ...data.pageContext.data } urlName={ urlName } lang={ lang } excludeReadtime={ excludeReadtime }/>
          <BackToTopSign/>
          <ShareButtons url={ url }
                        title={ articleTitle }/>
          <Disqus config={ disqusConfig }/>
        </div>
      </section>
      <FormNewsletter formNewsletter={ formNewsletter }/>
      <CallToAction cta={ cta }/>
      <GreyFooter footer={ footer } lang={ lang }/>
      <FundingBanner banner={ banner }/>
    </>
  );
};

export default BlogPostTemplate;
